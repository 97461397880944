<template>
    <CRow>
        <CCol>
            {{$lang.profile.gst.GSTINFO}}
            <a href="javascript:" @click="showGSTResponse(id)">
                <i class="fas fa-eye"></i>
            </a>
        </CCol>

        <CModal
                :title="$lang.profile.gst.GSTINFO"
                size="lg"
                :show.sync="largeModal"
                :no-close-on-backdrop="true"
                color="primary"
        >
            <CCardBody>
                <CRow v-if="gstResponse">
                    <CCol md="9" class="align-self-center">
                        <CRow>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.gstin"
                                     :value="gstResponse.gstin"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.panNumber"
                                     :value="gstResponse.panNumber"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.gstinStatus"
                                     :value="gstResponse.gstinStatus"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.businessName"
                                     :value="gstResponse.businessName"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.taxpayerType"
                                     :value="gstResponse.taxpayerType"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.dateOfRegistration"
                                     :value="gstResponse.dateOfRegistration"></MakeCol>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.aadhaarValidationDate"
                                     :value="gstResponse.aadhaarValidationDate"></MakeCol>

                        </CRow>
                    </CCol>
                </CRow>
                <CRow v-if="gstResponse">
                    <CCol md="12" class="align-self-center">
                        <CRow>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.legalName"
                                     :value="gstResponse.legalName"></MakeCol>
                        </CRow>
                    </CCol>
                </CRow>
                <CRow v-if="gstResponse">
                    <CCol md="12" class="align-self-center">
                        <CRow>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.constitutionOfBusiness"
                                     :value="gstResponse.constitutionOfBusiness"></MakeCol>
                        </CRow>
                    </CCol>
                </CRow>
                <CRow v-if="gstResponse">
                    <CCol md="12" class="align-self-center">
                        <CRow>
                            <MakeCol :lg="6" :md="4" :sm="4" :title="$lang.profile.gst.address"
                                     :value="gstResponse.address"></MakeCol>
                        </CRow>
                     </CCol>
                </CRow>

                <template hide-footer #footer style="display: none">
                    <CButton
                            @click="darkModal = false"
                            color="danger"
                            style="display: none"
                    >Discard
                    </CButton
                    >
                    <CButton
                            @click="darkModal = false"
                            color="success"
                            style="display: none"
                    >Accept
                    </CButton
                    >
                </template>
            </CCardBody>
        </CModal>
    </CRow>
</template>
<script>
import {company_gst_view} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import MakeCol from "/src/components/MakeCol";

export default {
    name: "GSTResponse",
    mixins: [Mixin],
    components: {MakeCol},
    props: [ "id"],
    data() {
        return {
            heading: "",
            largeModal: false,
            gstResponse : [],
            self: this,
            module: company_gst_view,
        };
    },
    mounted() {
    },
    methods: {
        showGSTResponse(id) {
            // eslint-disable-next-line no-undef
            let self = this;
            axios
                .get(this.viewUrlApi(this.module, id))
                .then((response) => {
                    if (response.data.data) {
                        self.gstResponse = response.data.data;
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch(function (error) {
                });
            self.largeModal = true;
        },
    },
};
</script>
